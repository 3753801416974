import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import { SelectDeliverySection } from '../../components/sections';
import { CountryCode } from '../../utils/constants';

const SelectDeliveryPage = (): JSX.Element => {
  return (
    <Layout hideHeader hideFooter countryCode={CountryCode.SG}>
      <SEO title="Select Delivery" />
      <SelectDeliverySection />
    </Layout>
  );
};

export default SelectDeliveryPage;
